import React from 'react';
import Section from '../../layout/section/section';
import style from './SynopsisSection.module.scss';

const SynopsisSection = () => {
  return (
    <Section size="xs" className={style.SynopsisSection}>
      <p>
        September 11, 2001, the date of the most devastating terrorist attack in
        the western hemisphere “Post-WWII. Following the attack on the World
        Trade Center, the war on terror was declared. Consequently, Afghanistan
        and Iraq were victims of invasion and siege. The middle east was set on
        fire and chaos became the new normal. The world was convinced that
        Saddam Hussein had weapons of mass destruction. They were right in a
        sense, but the weapon wasn't nuclear. It was something entirely
        different. A genetically engineered “super-soldier” by the name Wahid.
        An underground secret experiment (project Lonestar) funded by Saddam
        Hussein himself, intending on wreaking havoc in his aftermath. Wahid has
        the set of skills to work as an independent agent on hostile territory,
        and as a weapon of mass destruction, he is more than capable of
        inflicting unparalleled destruction. The story begins in early 2015
        during the invasion by the Islamic State in Iraq. The Central
        Intelligence Agency gets a hold of information concerning Wahid's
        presence in an underground research bunker in Tal Afar, Iraq. As the
        story continues, we learn there are hidden forces at play. One has sent
        out its agents of conquest, war, famine, and death. The Four Horsemen
        will descend upon Wahid; hell, and fire will follow in their wake.
      </p>
      <p>
        The story of Wahid is inspired by true events, religious scripture, and
        geopolitics. Wahid is a force for freedom of speech, true democracy, and
        prosperity for everyone. We will truly test the limits of these virtues
        in our comic book. Nothing is sacred and holy to us; there will be no
        exemptions for scrutiny.
      </p>
    </Section>
  );
};

export default SynopsisSection;
